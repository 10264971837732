import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class TermsPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>Privacy Policy │ Cookin’Burger</title>
          <meta name="description" content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!" />
        </Helmet>
        <div className="l-main">
          <div className="l-term-section">
            <div className="section-inner_m">
              <div className="l-term-section-box">
                <div className="l-term-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                    Privacy Policy
                </div>
                <p className="l-term-section-cap wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                    Article 1 (Personal Information)<br />
                    The term "personal information" refers to "personal information" as defined in the Act on the Protection of Personal Information (Act No. 57 of 2003, hereinafter referred to as the "Personal Information Protection Act"), and refers to information about a living individual that can identify a specific individual by name, date of birth, or other description contained in the information, or that contains a personal identification code.<br />
                    <br />
                    Article 2 (Acquisition and Use of Personal Information)<br />
                    We will acquire and use the personal information of the person concerned to the extent necessary for the following purposes. In the event that we use personal information beyond the scope of the following purposes, we will obtain the consent of the person in question in an appropriate manner in advance.<br />
                    (1) To provide our service (hereinafter referred to as "this service")<br />
                    (2) To improve the contents of the Service or to develop new services.<br />
                    (3) To provide information on new features, updates, campaigns, etc. of the Service and other services provided by the Company (including sending e-mails).<br />
                    (4) To contact users as necessary for maintenance, important notices, etc.<br />
                    (5) To respond to inquiries from users regarding the Service (including identification).<br />
                    (6) To report the usage status of the Service to users.<br />
                    (7) To request cooperation in questionnaires, interviews, etc. regarding the Service, to request participation in various events, or to report the results thereof.<br />
                    (8) To investigate and analyze the usage history of the Service and use the results for the improvement and development of the Service and for the distribution of advertisements.<br />
                    (9) To identify users who have violated the Terms of Use or who are trying to use the Service for illegal or improper purposes, and to refuse their use.<br />
                    <br />
                    Article 3 (Management and Protection of Personal Information)<br />
                    Personal information shall be strictly managed, and data shall not be disclosed or provided to third parties without the consent of the individual, except in the following cases. In addition, in consideration of safety, we will take measures to prevent and correct risks such as unauthorized access to personal information, loss, destruction, falsification, and leakage of personal information.<br />
                    (1) When it is necessary to protect the life, body, or property of an individual and it is difficult to obtain the consent of the individual.<br />
                    (2) Cases in which the provision of personal information is particularly necessary for improving public health or promoting the sound growth of children, and in which it is difficult to obtain the consent of the individual.<br />
                    (3) When it is necessary to cooperate with a national agency, a local public body, or a person or organization entrusted by either of the foregoing in executing affairs prescribed by laws and regulations and obtaining the consent of the individual is likely to impede the execution of such affairs.<br />
                    (4) When the handling of personal information is entrusted, in whole or in part, to a third party within the scope necessary to achieve the purpose of use in order to carry out business operations smoothly.<br />
                    (5) When personal information is provided in connection with the succession of a business due to a merger or other reasons.
                    (6) Other cases permitted by law.<br />
                    <br />
                    Article 4 (Joint Use)<br />
                    The Company may jointly use the User's personal data with the Company's partner companies as follows.<br />
                    1 Items of personal data to be used jointly<br />
                    Name, e-mail address, country of residence, address, telephone number, date of birth, gender, product purchase and transaction history<br />
                    2 Jointly used companies<br />
                    Digital Entertainment Asset Pte.Ltd.<br />
                    3 Purposes of use by the parties 　<br />
                    To inform you of the products and services of our collaborating companies<br />
                    4 Name of the party responsible for the management of the personal data<br />
                    Digital Entertainment Asset Pte.Ltd.<br />
                    <br />
                    Article 5 (Entrustment of the Handling of Personal Information) <br />
                    The Company may entrust the handling of personal information, in whole or in part, within the scope necessary to achieve the purpose of use. In this case, the Company shall thoroughly screen the eligibility of the consignee, stipulate matters concerning confidentiality in the contract, and conduct necessary and appropriate supervision of the consignee.<br />
                    <br />
                    Article 6 (Disclosure of Personal Information)<br />
                    When the Company is requested by a User to disclose their personal information in accordance with the provisions of the Act on the Protection of Personal Information, the Company shall disclose such personal information to the User without delay after confirming that the request is made by the User themself (if such personal information does not exist, the Company shall notify the User to that effect). However, this does not apply in cases where we are not obligated to disclose the information under the Personal Information Protection Law or other laws and regulations. Please note that a handling fee (1,000 yen per case) will be charged for the disclosure of personal information.<br />
                    <br />
                    Article 7 (Correction and Suspension of Use of Personal Information)<br />
                    1 In the event that the Company is requested by a User to correct the content of their personal information in accordance with the provisions of the Personal Information Protection Law on the grounds that the personal information is untrue, the Company shall, except in cases where special procedures are prescribed by other laws and regulations, conduct the necessary investigation without delay after confirming that the request is made by the User or the person themself, and, based on the results, correct the content or suspend the use of the personal information and notify the User to that effect.<br />
                    2 In the event that the Company is requested by a User to suspend or delete the use of their personal information (hereinafter referred to as "Suspension of Use, etc.") on the grounds that the personal information is being handled beyond the scope of the previously announced purpose of use, or that the information was obtained by wrongful means, the Company will do so. (hereinafter referred to as "cessation of use, etc.") on the grounds that the personal information is being handled inappropriately or that it was obtained through improper means, the Company will conduct the necessary investigation without delay, cessation of use, etc. of the personal information based on the results of the investigation, and notify the person to that effect. However, in cases where it is difficult to suspend the use of personal information due to high costs or other reasons, and where alternative measures necessary to protect the rights and interests of the individual can be taken, such alternative measures will be taken.<br />
                    <br />
                    Article 8 (Procedures for Changing the Privacy Policy)<br />
                    The Company will review the contents of this Policy from time to time and make efforts to improve it. The Company reserves the right to change the contents of this policy, except as otherwise provided by law or in this policy. The revised Privacy Policy shall become effective when notified to users by the method prescribed by the Company or posted on the Company's website.<br />
                    <br />
                    Article 9 (Contact for Inquiries)<br />
                    For opinions, questions, complaints, and other inquiries regarding the handling of personal information, please contact the following<br />
                    <br />
                    Address: 2-106 Kosugi Goten-cho, Nakahara-ku, Kawasaki City, Kanagawa Prefecture<br />
                    BurgerStudio Co., Ltd.<br />
                    Person in charge of handling personal information: Takafumi Kiyota<br />
                    Contact: support@burgerstudio.jp<br />
                    <br />
                    Special Provisions for EU Users<br />
                    Notwithstanding any other provision of this Policy, in jurisdictions where the EU General Data Protection Regulation ("GDPR") is applicable, information regarding the rights of EU Users under that regulation and the Company's responsibilities is set forth below.<br />
                    <br />
                    (a) Rights of EU Users<br />
                    Users of the Service in the EU have the following rights with respect to the Company's handling of their personal information.
                    (1) Explanation and copy of the user's data<br />
                    The User has the right to request an explanation of the User's information held by the Company and an explanation of how the Company uses the User's personal information.<br />
                    In addition to the above, if we have collected your information based on your consent or because it is necessary to provide a service requested by you, you have the right to receive a copy of the information we have collected about you.<br />
                    (2) Correction<br />
                    If you believe that any information we have about you is inaccurate, you have the right to request that we correct your information.<br />
                    (3) Deletion<br />
                    The user may, at any time, request that their account be deleted. You may request that your account be removed from our website. We may retain certain information about you if required to do so by law or for legitimate business purposes as permitted by law.<br />
                    (4) Objections and Complaints<br />
                    Users in the EU have the right to object to the processing of their personal data by the Company. The processing of personal data includes processing for profiling and marketing purposes with automated decision-making. In the event of an objection, the Company may continue to process the User's data to the extent permitted by the GDPR, and the objection does not immediately lead to the deletion or other modification of the processing of the personal data.<br />
                    <br />
                    (b) Processing of Information<br />
                    We process the information of EU users on the basis of the grounds set out in the GDPR. The details of the basis are as follows.<br />
                    (1) Collection and use of information for the provision of services<br />
                    In order for us to provide our services, we need to collect and use certain information. This includes the following information<br />
                    - User profile information required to create and maintain a User account. Information necessary for the Company to contact the User about the User's purchases and account, and for the User to make payments.<br />
                    - Transaction information that needs to be created and maintained in connection with the User's use of the Service.<br />
                    (2) Disclosure of Information to Government Agencies and Investigative Agencies for Security Purposes<br />
                    The Company may process personal information, including the act of disclosing data to government agencies and investigative bodies, when the safety of the User or a third party is threatened.<br />
                    (3) Collection and use of information incidental to the provision of services<br />
                    The Company will collect and use personal information only to the extent necessary to protect the Company's legitimate interests. This includes the collection and use of information for the following purposes.<br />
                    - To maintain or improve the security of our users.<br />
                    - To prevent and detect fraudulent activities related to the Service and to respond to such activities.
                    - To provide information to authorities of government agencies and investigative bodies regarding criminal activities or threats to public safety.<br />
                    - To provide customer support.<br />
                    - To conduct research and analysis.<br />
                    - To conduct direct marketing.<br />
                    - To enforce our Terms of Use.<br />
                    (4) Collection and use of information to protect the interests of third parties or the public<br />
                    The Company will collect and use personal information to the extent necessary to protect the interests of third parties or the public.<br />
                    In addition to the above, we may collect and use information in accordance with applicable laws when necessary with respect to important public interests.<br />
                    (5) Collection and Use of Information Based on Consent<br />
                    We may collect and use your information based on your consent. Consent can be revoked at any time. If you revoke your consent, you will not be able to use any services or functions that require the information that we have collected or used based on your consent.<br />
                    <br />
                    <br />
                    Revision History<br />
                    Enacted on February 7, 2022
                </p>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

TermsPage.propTypes = propTypes

export default TermsPage

export const pageQuery = graphql`
  query PrivacyEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`